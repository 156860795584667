body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  Main application colors
*/
:root {
  --c-orange: #fd6d00;
  --c-dark: #333333;
  --c-light: #ffffff;
}

.b24-widget-button-wrapper {
  /* z-index: 10 !important; */
}